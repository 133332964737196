//我的合同
<template>
  <div style="min-width: 1381px;">
    <el-row :gutter="15" style="min-height:calc(100vh - 130px)" class="flex">
      <el-col :span="16">
        <!-- <business></business> -->
        <el-card style="height:100%" class="pr">
          <div class="box-card flex">
            <div class="mt-30 mr-30">
              <span class="mr-10 f14">合同编号</span>
              <el-input v-model="contract_code"></el-input>
            </div>
            <div class="mt-30 mr-30">
              <span class="mr-10 f14">合同日期</span>
              <el-date-picker v-model="addtime" value-format="yyyy-M-d" type="date"></el-date-picker>
            </div>
            <div class="mt-30 mr-30">
              <span class="mr-10 f14">合同状态</span>
              <el-select v-model="check_status" class="w-200">
                <el-option label="待签字" value="1"></el-option>
                <el-option label="待盖章" value="2"></el-option>
                <el-option label="待缴费" value="3"></el-option>
                <el-option label="待新建" value="4"></el-option>
                <el-option label="待审核" value="5"></el-option>
                <el-option label="不通过" value="6"></el-option>
                <el-option label="通过" value="7"></el-option>
                <el-option label="已完成" value="11"></el-option>
                <el-option label="已作废" value="12"></el-option>
                <el-option label="待退款" value="13"></el-option>
                <el-option label="已退款" value="14"></el-option>
                <el-option label="退款失败" value="15"></el-option>
                <el-option label="作废中" value="16"></el-option>
                <el-option label="作废" value="17"></el-option>
              </el-select>
            </div>
            <div class="mt-30 mr-30">
              <span class="mr-10 f14">业务类型</span>
              <el-select v-model="service_id" class="w-200">
                <el-option v-for="item in ydata" :key="item.id" :label="item.service_name" :value="item.id"></el-option>
              </el-select>
            </div>
            <el-button
              class="f14 mt-30 bg-b"
              style="width: 104px;height: 32px;padding:8px 10px;"
              @click="cx"
            >查询</el-button>
          </div>

          <div>
            <!-- <el-button class="mr-30 bg-b" @click="$router.push('/import')">专利导入</el-button> -->
            <!-- <el-button class="bg-b">专利年费到期查询</el-button>
            <el-button class="bg-b" @click="dialogVisible = true">年费批量操作</el-button> -->
          </div>
          <el-table
            max-height="590px"
            :data="tableData"
            border
            :header-cell-style="{color:'#222530', fontSize: '14px', backgroundColor: '#eaf1ff',borderColor: '#ccc'}"
            style="font-size: 10px; margin-top:30px;"
          >
            <el-table-column prop="contract_code" label="合同编号" align="center"></el-table-column>
            <el-table-column label="合同状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="scope.row.check_status==3?'warning':scope.row.check_status==1?'':'info'" :class="scope.row.check_status==1||scope.row.check_status==3?'cur':'' " @click="tz(scope.row.check_status,scope.row.qr_url,scope.row)">
                  {{scope.row.check_status==-1?'待处理':
                  scope.row.check_status==1?'待签字':
                  scope.row.check_status==2?'待盖章':
                  scope.row.check_status==3?'待缴费':
                  scope.row.check_status==4?'待新建':
                  scope.row.check_status==5?'待审核':
                  scope.row.check_status==6?'不通过(经理)':
                  scope.row.check_status==7?'通过(经理)':
                  scope.row.check_status==8?'不通过(总监)':
                  scope.row.check_status==9?'通过(总监)':
                  scope.row.check_status==19?'不通过(客服主管)':
                  scope.row.check_status==20?'通过(客服主管)':
                  scope.row.check_status==22?'(行政不通过)':
                  scope.row.check_status==23?'(行政通过)':
                  scope.row.check_status==10?'不通过(财务)':
                  scope.row.check_status==11?'已完成1':
                  scope.row.check_status==2?'已作废':
                  scope.row.check_status==13?'待退款':
                  scope.row.check_status==14?'已退款':
                  scope.row.check_status==15?'退款失败':
                  scope.row.check_status==16?'作废中':
                  scope.row.check_status==17?'作废不通过':
                  scope.row.check_status==18?'微信支付':
                  scope.row.check_status==21?'流程不通过':''}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="servicetype.serviceType_name" label="业务类型" align="center"></el-table-column>
            <el-table-column prop="client.client_name" label="合同签订人" align="center"></el-table-column>
            <el-table-column prop="contract_price" label="合同金额(元)" align="center"></el-table-column>
            <el-table-column prop="addtime" label="签订时间" align="center"></el-table-column>
            <el-table-column  label="操作" align="center">
              <template slot-scope="scope">
                <el-tag effect="plain" @click="yulan(scope.row.stamp_pdf)" class="mr-10 cur" >预览</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="t-c mt-20" v-show="total>0">
            <el-pagination
              @current-change="changePage"
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pagesize"
              :current-page.sync='currentpage'
            ></el-pagination>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <div style="height:100%" class="gd">
          <monitor></monitor>
        <infor style="flex:1"></infor>
        </div>
       
      </el-col>
    </el-row>
    <el-dialog :visible.sync="dialogVisible" width="390px">
      <div style="padding:10px;background: #c4c4c4; width:130px;height:130px;margin:50px auto 70px">
        <div style="padding:10px;background: #fff;width:110px;height:110px;">
          <div ref="qrCodeUrl"></div>
        </div>
      </div>
      <div class="t-c bd">请扫描二维码，前往智易宝小程序签字</div>
    </el-dialog>
  </div>
</template>

<script>
import business from "./common/business";
import monitor from "./common/monitor";
import infor from "./common/Infor";
import QRCode from "qrcodejs2";
export default {
  components: {
    business,
    monitor,
    infor
  },
  data() {
    return {
      total: 0,
      pagesize: 7,
      page: 1,
      dialogVisible: false,
      tableData: [],
      contract_code:'',
      check_status:'',
      service_id:'',
      addtime:'',
      currentpage:1,
      ydata:[]
    };
  },
  created() {
     this.getydata()
    this.getlist();
  },
  methods: {
    getlist() {
      this.$request
        .get(
          `/client/contract/listData?page=${this.page}&limit=${
            this.pagesize
          }&contract_code=${this.contract_code}&check_status=${this.check_status}&service_id=${this.service_id}&addtime=${this.addtime}`
        )
        .then(res => {
          if (res.data.code == 200) {
            this.tableData = res.data.data;
            this.total=res.data.total
          }
        });
    },
    wCode(code) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.qrCodeUrl.innerHTML = "";
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: code,
          width: 110,
          height: 110,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H
        });
      });
    },
    tz(index,url,item){
      if(index==1){
       this.wCode(url)
      }
      if(index==3){
        let  price=item.contract_price
        let id=item.id
        let type=5
        this.$router.push({ path: "/confirm", query: {type,id,price } });
      }
    },
    cx() {
        this.page = 1;
        this.currentpage=1
        this.getlist()
    },
    changePage(index) {
      this.page = index;
      this.getlist()
    },
    // 业务查询数据
    getydata(){
      this.$request.get("/client/common/service").then(res=>{
        if(res.data.code==200){
          this.ydata=res.data.data
        }
      })
    },
    yulan(url){
       let time1=new Date().getTime()
      let url1=`https://view.officeapps.live.com/op/view.aspx?src=${url}?time=${time1}`
      window.open(url)
    }
  },
  watch: {
    addtime(newVal){
       if (newVal == null) {
        this.addtime = '';
      }
    }
  },
};
</script>

<style  scoped>
.box-card {
  padding-bottom: 48px;
  flex-wrap: wrap;
}
.el-input {
  height: 32px;
  width: 200px;
}
.el-card >>> .el-input__inner {
  height: 32px;
  border-color: #bbb;
}
.el-card >>> .el-input__inner:focus {
  border-color: #2962ff;
}
.w-200 {
  width: 200px;
}
.el-card >>> .el-switch.is-checked .el-switch__core {
  border-color: #2962ff;
  background-color: #2962ff;
}
.el-card >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2962ff;
  color: #fff;
}
.el-card >>> .el-button:focus,
.el-button:hover {
  color: #fff;
  border-color: #2962ff;
  background-color: #2962ff;
}
.el-card >>>.el-input__icon {

    line-height: 33px;
}
.gd{
  display: flex;
  flex-direction: column
}

</style>